import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAppSelector } from 'src/redux/hooks';
import store from 'src/redux/store';
import { hideInternalServerView } from 'src/redux/slices/internalServerSlice';
import ERROR404 from 'src/assets/error_404.svg';
import { Button } from '@mui/material';
import { ErrorMessage1, ErrorMessage2, Wrapper } from '../Styles';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';


export const NotFound = () => {
  const navigate = useNavigate(); 
  const {color} = useContext(ThemeContext);

  const { isInternalServerError } = useAppSelector((state: any) => state.internalServerError);

  const onClick = () => {
    if (isInternalServerError) {
      store.dispatch(hideInternalServerView());
    }
    navigate('./')
  }
  const { t } = useTranslation();

  return (
    <Wrapper>
      <img src={ERROR404} alt='error' />
      <ErrorMessage1>
        {t('Page not found')}
      </ErrorMessage1>
      <ErrorMessage2>
        {t('Sorry, the page you are looking for does not exist')}
      </ErrorMessage2>
      <Button
        onClick={onClick}
        variant='contained'
        sx={{color:color.btnTextPrimary}}

      >
        {t('Back to Home')}
      </Button>
    </Wrapper >
  )
};
